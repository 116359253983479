<template>
    <div class="container px-1 py-5" dusk="ticket-edit" v-if="ticket">
        <div class="flex justify-between mb-5 md:mb-10">
            <h1 class="lg:text-3xl text-2xl">{{ ticket.title }}</h1>

            <div class="shrink-0">
                <c-button class="bg-slate-200 mr-2 text-slate-500" v-on:click="router.back()">
                    {{ __('Cancel') }}
                </c-button>
                <c-button class="bg-success lg:min-w-[200px] text-white" dusk="save-ticket-button" v-on:click="submit">
                    {{ __('Save') }}
                </c-button>
            </div>
        </div>
        <div class="flex flex-col-reverse lg:flex-row items-start">
            <div class="flex flex-col lg:mr-4 lg:w-2/3 w-full">
                <div class="bg-white mb-4 p-2 rounded shadow-sm">
                    <h2 class="font-medium mb-5 text-lg">{{ __('General information') }}</h2>

                    <label class="block font-bold mb-1 text-sm" for="ticket.title">{{ __('Ticket title') }}</label>
                    <c-control-text class="mb-5" id="ticket.title" name="ticket.title"
                                    v-bind:invalid="!!requestStore.errors?.title"
                                    v-model="ticket.title"
                    />

                    <label class="block font-bold mb-1 text-sm" for="ticket.notes">{{ __('Ticket description') }}</label>
                    <c-control-text class="mb-5" dusk="description-field" id="ticket.description" name="ticket.description" type="textarea"
                                    v-bind:class="{ 'border-error' : !!requestStore.errors?.description}"
                                    v-model="ticket.description"
                    />

                    <label class="block font-bold mb-1 text-sm" for="ticket.notes">{{ __('Add notes') }}</label>
                    <c-control-text class="mb-5" dusk="notes-field" id="ticket.notes" name="ticket.notes" type="textarea"
                                    v-bind:class="{ 'border-error' : !!requestStore.errors?.notes}"
                                    v-model="ticket.notes"
                    />

                    <strong class="block text-sm">{{ __('Billable?') }}</strong>
                    <c-control-toggle dusk="billable-switch" name="ticket.billable"
                                      v-bind:invalid="!!requestStore.errors?.is_billable"
                                      v-bind:options="[ { value : 'yes', label: __('Yes') } ]"
                                      v-model="ticket.is_billable"
                    />
                </div>

                <div class="bg-white mb-4 p-2 rounded shadow-sm">
                    <h2 class="font-medium mb-5 text-lg">{{ __('Scheduling') }}</h2>

                    <strong class="block mb-1 text-sm">{{ __('Start time') }}</strong>
                    <div class="flex items-center justify-between mb-5">
                        <c-control-date class="mr-4 w-1/2"
                                        icon="calendar"
                                        icon-position="left"
                                        name="ticket.schedule_start_date"
                                        v-bind:enable-time-picker="false"
                                        v-bind:format="localizationStore.dateFormat"
                                        v-bind:invalid="!!requestStore.errors?.schedule_start_date"
                                        v-model="ticket.schedule_start_date"
                                        v-on:update:model-value="checkScheduleOverlaps"
                        />

                        <c-control-date class="w-1/2"
                                        icon="clock"
                                        icon-position="left"
                                        minutes-increment="5"
                                        name="ticket.schedule_start_time"
                                        time-picker
                                        v-bind:invalid="!!requestStore.errors?.schedule_start_time"
                                        v-bind:start-time="{ hours: 8, minutes: 0 }"
                                        v-model="ticket.schedule_start_time"
                                        v-on:update:model-value="checkScheduleOverlaps"
                        />
                    </div>

                    <strong class="block mb-1 text-sm">{{ __('End time') }}</strong>
                    <div class="flex items-center justify-between mb-5">
                        <c-control-date class="mr-4 w-1/2"
                                        name="ticket.schedule_end_date"
                                        icon="calendar"
                                        icon-position="left"
                                        v-bind:enable-time-picker="false"
                                        v-bind:format="localizationStore.dateFormat"
                                        v-bind:invalid="!!requestStore.errors?.schedule_end_date"
                                        v-model="ticket.schedule_end_date"
                                        v-on:update:model-value="checkScheduleOverlaps"
                        />

                        <c-control-date class="w-1/2"
                                        name="ticket.schedule_end_time"
                                        icon="clock"
                                        icon-position="left"
                                        minutes-increment="5"
                                        time-picker
                                        v-bind:invalid="!!requestStore.errors?.schedule_end_time"
                                        v-bind:start-time="{ hours: 8, minutes: 0 }"
                                        v-model="ticket.schedule_end_time"
                                        v-on:update:model-value="checkScheduleOverlaps"
                        />
                    </div>

                    <router-link class="cursor-pointer flex items-center mb-5 -mt-2 relative text-xs" target="_blank"
                                 v-bind:to="{
                                        name : overlapTicket.worksheet ? 'tickets.show' : 'tickets.edit',
                                        params: { ticket : overlapTicket.id }
                                     }"
                                 v-for="overlapTicket in overlapTickets"
                    >
                        <c-icon class="h-6 mr-2 stroke-error w-6" icon="alert-triangle" />

                        {{ __(':name already has another task scheduled at this time. Click for details.', { name : overlapTicket.assignee_name }) }}
                    </router-link>

                    <strong class="block text-sm">{{ __('Recurring?') }}</strong>
                    <c-control-toggle name="ticket.recurrence"
                                      v-bind:invalid="!!requestStore.errors?.recurrence"
                                      v-bind:options="[ { value : 'yes', label: __('Yes') } ]"
                                      v-model="ticket.recurrence"
                    />
                    <div class="bg-slate-100 p-2" v-if="ticket.recurrence">
                        <c-control-date class="mb-4" name="ticket.recurrence.next_occurrence"
                                        v-bind:invalid="!!requestStore.errors?.next_occurrence"
                                        v-bind:enable-time-picker="false"
                                        v-bind:label="__('Next occurrence')"
                                        v-bind:format="localizationStore.dateFormat"
                                        v-bind:min-date="DateTime.now().plus({ days: 1}).toString()"
                                        v-model="ticket.next_occurrence"
                        />

                        <div class="font-medium gap-4 grid grid-cols-3 items-end mb-4 text-sm">
                            <div class="flex items-center">
                                {{ __('Every') }}
                                <c-control-number class="mx-2 w-12" name="ticket.recurrence.interval"
                                                  v-bind:invalid="!!requestStore.errors?.recurrence_interval"
                                                  v-model="ticket.recurrence_interval"
                                />

                                <c-control-select name="ticket.recurrence.frequency"
                                                  v-bind:invalid="!!requestStore.errors?.recurrence_frequency"
                                                  v-bind:options="frequencies"
                                                  v-model="ticket.recurrence_frequency"
                                />
                            </div>
                            <div class="border-l border-slate-200 col-span-2 flex items-center ml-10"
                                 v-if="ticket.recurrence_frequency === 'weeks'"
                            >
                                <span class="border border-l-0 cursor-pointer flex h-8 items-center justify-center w-8"
                                      v-bind:class="{ 'bg-success border-success text-white' : value, 'bg-white border-slate-200' : !value }"
                                      v-for="(value, day, index) in ticket.recurrence_constraints.weeks"
                                      v-on:click="ticket.recurrence_constraints.weeks[day] = !ticket.recurrence_constraints.weeks[day];"
                                >
                                    {{ __('weekdays.' + day) }}
                                </span>
                            </div>
                            <c-control-select name="ticket.recurrence_constraints.months.interval"
                                              v-bind:options="constraint_intervals"
                                              v-if="ticket.recurrence_frequency === 'months'"
                                              v-model="ticket.recurrence_constraints.months.interval"
                            />

                            <c-control-select class="ml-10" name="ticket.recurrence_constraints.months.type"
                                              v-bind:options="constraint_types"
                                              v-if="ticket.recurrence_frequency === 'months' && ticket.recurrence_constraints.months.interval !== 'date'"
                                              v-model="ticket.recurrence_constraints.months.type"
                            />
                        </div>
                    </div>
                </div>

                <div class="bg-white mb-4 p-2 rounded shadow-sm">
                    <h2 class="font-medium mb-5 text-lg">{{ __('Assignees') }}</h2>

                    <strong class="block mb-1 text-sm">{{ __('Primary assignee') }}</strong>
                    <c-control-autocomplete class="mb-5" dusk="assignee-field"
                                            avatar-field="avatar" label-field="name" name="ticket.assignee"
                                            v-bind:invalid="!!requestStore.errors?.assignee_id"
                                            v-bind:placeholder="__('Search by employee name')"
                                            v-bind:source-get="employeeGet"
                                            v-bind:source-search="employeeSearch"
                                            v-model="ticket.assignee"
                                            v-on:update:model-value="checkScheduleOverlaps"
                    />

                    <strong class="block mb-1 text-sm">{{ __('Additional assignees') }}</strong>
                    <c-control-tag avatar-field="avatar" label-field="name" name="ticket.additional_assignees"
                                   v-bind:invalid="!!requestStore.errors?.additional_assignees"
                                   v-bind:placeholder="__('Search by employee name')"
                                   v-bind:source-get="employeeGet"
                                   v-bind:source-search="employeeSearch"
                                   v-model="ticket.additional_assignees"
                                   v-on:update:model-value="checkScheduleOverlaps"
                    />
                </div>

                <div class="bg-white mb-4 p-2 rounded shadow-sm">
                    <h2 class="font-medium mb-5 text-lg">{{ __('Devices') }}</h2>

                    <c-control-tag name="ticket.serial_numbers"
                                   v-bind:erroneous-tags="snsAtAnotherSite"
                                   v-bind:invalid="!!requestStore.errors?.serial_numbers"
                                   v-bind:label-field="(data) => {
                                        let label = data.part_number.make || data.part_number.model
                                            ? '(' + data.sn + ')'
                                            : data.sn;

                                        if (data.part_number.model) {
                                             label = data.part_number.model + ' ' + label;
                                        }

                                        if (data.part_number.make) {
                                             label = data.part_number.make + ' ' + label;
                                        }

                                       return label;
                                   }"
                                   v-bind:placeholder="__('Search by name or S/N')"
                                   v-bind:source-get="snGet"
                                   v-bind:source-search="snSearch"
                                   v-model="ticket.serial_numbers"
                                   v-on:update:model-value="checkSnsAtAnotherSite"
                    />

                    <span class="flex items-center mt-2 text-xs" v-if="snsAtAnotherSite.length > 0">
                        <c-icon class="h-6 mr-2 stroke-error w-6" icon="alert-triangle" />

                        {{ __('The devices marked with red are located at a different partner or site. Adding them to this ticket will automatically update location information.') }}
                    </span>
                </div>

                <div class="bg-white mb-4 p-2 rounded shadow-sm">
                    <div class="flex items-center justify-between mb-5">
                        <h2 class="font-medium text-lg">{{ __('Tasks') }}</h2>

                        <div class="relative">
                            <c-button class="after:border-[5px] after:border-transparent after:!border-t-highlight after:content-[''] after:h-0 after:ml-2 after:mt-1 after:pointer-events-none after:transition-all after:w-0 bg-slate-100 hover:after:!border-t-white hover:bg-highlight hover:!text-white !text-highlight"
                                      v-bind:class="{ 'after:!border-t-white bg-highlight text-white' : showChecklistMenu }"
                                      v-on:click="showChecklistMenu = !showChecklistMenu"
                                      v-on-click-outside="closeChecklistMenu"
                            >
                                {{ __('Apply checklist') }}
                            </c-button>

                            <nav class="absolute bg-white border border-slate-200 flex flex-col left-0 min-w-[200px] mt-2 rounded shadow-sm text-sm top-full whitespace-nowrap z-10"
                                 v-show="showChecklistMenu"
                            >
                                <span class="border-b border-b-slate-100 flex hover:bg-slate-50 items-center justify-between"
                                      v-for="checklist in checklists"
                                      v-if="checklists.length > 0"
                                >
                                    <span class="block cursor-pointer p-2 w-full" v-on:click="applyChecklist(checklist)">
                                        {{ checklist.name }}
                                    </span>
                                    <a class="duration-500 group/icon hover:bg-error mx-2 p-1 rounded transition-colors"
                                        v-if="can('checklists.destroy')"
                                        v-on:click="deletedChecklist = checklist"
                                    >
                                        <c-icon class="cursor-pointer group-hover/icon:stroke-white h-4 stroke-error w-4"
                                                icon="trash-2"
                                        />
                                    </a>
                                </span>
                                <span class="border-b border-b-slate-100 p-2 text-center text-slate-400"
                                      v-else
                                >
                                    {{ __('There are no checklists available.') }}
                                </span>

                                <a class="cursor-pointer flex hover:bg-slate-50 items-center p-2"
                                   v-bind:class="{ '!cursor-not-allowed pointer-events-none text-slate-400' : ticket.tasks.length === 0 }"
                                   v-if="can('checklists.store')"
                                   v-on:click="addingChecklist = true"
                                >
                                    <c-icon class="h-4 mr-2 stroke-slate-800 w-4" icon="plus"
                                            v-bind:class="{ '!stroke-slate-400' : ticket.tasks.length === 0 }"
                                    />
                                    {{ __('Create checklist from current tasks') }}
                                </a>
                            </nav>
                        </div>
                    </div>

                    <ul class="mb-2" dusk="tasks-container">
                        <li class="cursor-pointer flex group hover:text-error items-center mb-1 relative text-sm w-full"
                            v-for="(task, key) in ticket.tasks"
                            v-on:click="deleteTask(key)"
                            v-on:mouseover="selectedTask = key"
                            v-on:mouseout="selectedTask = null"
                        >
                            <c-icon class="group-hover:stroke-error h-4 mr-2 stroke-slate-500 transition-none w-4"
                                    v-bind:icon="selectedTask === key ? 'trash-2' : 'square'"
                            />
                            <span>{{ task.name }}</span>
                        </li>
                    </ul>
                    <div class="flex items-center mt-4 w-full">
                        <c-control-text class="mr-2 w-full" name="addedTask"
                                        v-bind:placeholder="__('Task description')"
                                        v-model="addedTask"
                                        v-on:keyup.enter="addTask"
                        />
                        <c-button class="bg-highlight" dusk="add-task-button"
                                  v-bind:class="{ 'bg-slate-800' : addedTask === '' }"
                                  v-bind:disabled="addedTask === ''"
                                  v-on:click="addTask"
                        >
                            <c-icon class="h-3 stroke-white w-3" icon="corner-down-left" />
                        </c-button>
                    </div>
                </div>

                <div class="bg-white mb-4 p-2 rounded shadow-sm">
                    <h2 class="font-medium mb-5 text-lg">{{ __('Files') }}</h2>

                    <div class="grid grid-cols-3 gap-4 md:grid-cols-6" dusk="files-container">
                        <a class="bg-white border border-slate-200 cursor-pointer duration-500 flex flex-col group hover:border-slate-500 items-center justify-center p-2 relative rounded transition-all" target="_blank"
                           v-bind:href="file.url"
                           v-for="(file, key) in ticket.files"
                        >
                            <img class="max-h-32 rounded"
                                 v-bind:alt="file.name"
                                 v-bind:src="file.url"
                                 v-if="isImage(file.name)"
                            />
                            <c-icon class="group-hover:stroke-slate-500 h-10 stroke-slate-300 w-10" icon="file"
                                    v-else
                            />

                            <span class="block mt-1 overflow-hidden text-center text-ellipsis text-xs whitespace-nowrap w-full">
                                {{ file.name }}
                            </span>

                            <span class="absolute bg-error cursor-pointer hidden group-hover:block p-1 -right-2 rounded-full text-sm -top-2 text-error" dusk="delete-file-button"
                               v-on:click.prevent="deleteFile(key)"
                            >
                                <c-icon class="h-4 stroke-white w-4" icon="trash-2" />
                            </span>
                        </a>

                        <a class="bg-white border border-slate-200 cursor-pointer duration-500 flex flex-col group hover:border-slate-500 items-center justify-center p-2 relative rounded transition-all" target="_blank"
                           v-bind:href="newFiles[key]"
                           v-for="(file, key) in newFilenames"
                        >
                            <img class="max-h-32 rounded"
                                 v-bind:alt="file"
                                 v-bind:src="newFiles[key]"
                                 v-if="isImage(file)"
                            />
                            <c-icon class="group-hover:stroke-slate-500 h-10 stroke-slate-300 w-10" icon="file"
                                    v-else
                            />

                            <span class="block mt-1 overflow-hidden text-center text-ellipsis text-xs whitespace-nowrap w-full">
                                {{ file }}
                            </span>

                            <span class="absolute bg-error cursor-pointer hidden group-hover:block p-1 -right-2 rounded-full text-sm -top-2 text-error"
                                  v-on:click.prevent="deleteFile(key, true)"
                            >
                                <c-icon class="h-4 stroke-white w-4" icon="trash-2" />
                            </span>
                        </a>

                        <a class="bg-white border border-slate-200 cursor-pointer duration-500 flex group hover:border-slate-500 items-center justify-center p-2 rounded transition-all"
                           v-on:click="upload"
                        >
                            <c-icon class="group-hover:stroke-slate-500 h-10 stroke-slate-300 w-10" icon="plus" />
                        </a>
                    </div>
                    <c-control-file class="h-0 opacity-0 pointer-events-none w-0" multiple name="ticket.files" v-bind:filenames="newFilenames" v-model="newFiles" />
                </div>

                <div class="bg-white p-2 rounded shadow-sm" v-if="fields.length > 0 && ticket.fieldValues">
                    <h2 class="font-medium text-lg">{{ __('Other information') }}</h2>

                    <template v-for="field in fields">
                        <div class="mt-5">
                            <div class="bg-indigo-50 group mb-1 p-2 rounded"
                                 v-for="repetition in computeFieldRepetitions(field)"
                                 v-if="field.fields && field.fields.length > 0"
                            >
                                <div class="flex items-center justify-between mb-4">
                                    <strong class="text-sm">{{ field.label }}</strong>
                                    <a class="cursor-pointer duration-500 group-hover:pointer-events-auto group-hover:visible group/icon hover:bg-error invisible p-1 pointer-events-none rounded transition-colors"
                                       v-if="repetition > 1"
                                       v-on:click="deleteFieldRepetition(field, repetition)"
                                    >
                                        <c-icon class="group-hover/icon:stroke-white h-4 stroke-error w-4" icon="trash-2" />
                                    </a>
                                </div>
                                <div class="mb-5"
                                     v-for="subfield in field.fields"
                                >
                                    <custom-field v-bind:field="subfield"
                                                  v-bind:id="field.key + '.' + (repetition-1) + '.' + subfield.key"
                                                  v-model="ticket.fieldValues[field.key + '.' + (repetition-1) + '.' + subfield.key]"
                                    />
                                </div>
                                <a class="bg-highlight cursor-pointer ml-auto p-1 rounded table"
                                   v-if="field.repeatable && repetition === computeFieldRepetitions(field)"
                                   v-on:click="addFieldRepetition(field)"
                                >
                                    <c-icon class="h-6 stroke-white w-6" icon="plus" />
                                </a>
                            </div>
                            <custom-field v-bind:field="field"
                                          v-bind:id="field.key"
                                          v-else
                                          v-model="ticket.fieldValues[field.key]"
                            />
                        </div>
                    </template>
                </div>
            </div>
            <div class="bg-white flex flex-col lg:mb-0 lg:w-1/3 mb-4 p-2 rounded shadow-sm w-full">
                <div class="flex items-center justify-between w-full">
                    <div>
                        <span class="mr-2 text-slate-500">#{{ ticket.oid }}</span>
                        <span class="text-sm">{{ ticket.title }}</span>
                    </div>
                    <span class="ml-4 shrink-0 text-slate-500 text-xs">
                        {{
                            DateTime.fromISO(
                                ticket.updated_at,
                                { locale : localizationStore.locale }
                            ).toRelative()
                        }}
                    </span>
                </div>

                <strong class="mb-1 mt-5 text-sm">{{ __('Partner') }}</strong>
                <span class="text-sm">{{ ticket.site?.partner.name }}</span>

                <strong class="mb-1 mt-5 text-sm">{{ __('Site') }}</strong>
                <div class="text-sm">
                    <span class="block">{{ ticket.site?.address_line_1 }}</span>
                    <span class="block" v-if="ticket.site?.address_line_2">{{ ticket.site.address_line_2 }}</span>
                    <span class="block">
                        <span v-if="ticket.site?.postcode">{{ ticket.site.postcode }} </span>
                        {{ ticket.site?.city }},
                        {{ ticket.site?.country }}
                    </span>
                </div>

                <strong class="mb-1 mt-5 text-sm" v-if="ticket.description">{{ __('Ticket description') }}</strong>
                <div class="text-sm whitespace-pre-wrap" v-if="ticket.description">{{ ticket.description }}</div>
            </div>
        </div>
    </div>

    <c-modal class="md:w-[450px]"
             v-bind:open="addingChecklist"
             v-bind:title="__('New checklist')"
             v-on:closeModal="addingChecklist = false"
    >
        <template v-slot:body>
            <c-control-text class="w-full" name="name" required
                            v-bind:invalid="!!requestStore.errors?.name"
                            v-bind:label="__('Checklist name')"
                            v-bind:placeholder="__('Start typing...')"
                            v-model="newChecklistName"
            />
        </template>
        <template v-slot:footer>
            <div class="flex justify-end w-full">
                <c-button class="bg-slate-100 mr-2 text-slate-800" v-on:click="addingChecklist = false">
                    {{ __('Cancel') }}
                </c-button>
                <c-button class="bg-success text-white"
                          v-bind:disabled="!can('checklists.store')"
                          v-on:click="addChecklist"
                >
                    {{ __('Save') }}
                </c-button>
            </div>
        </template>
    </c-modal>
    <c-modal class="md:w-[450px]"
             v-bind:open="deletedChecklist !== null"
             v-bind:title="__('Delete checklist')"
             v-on:closeModal="deletedChecklist = null"
    >
        <template v-slot:body>
            <div class="py-5 text-center text-sm">
                {{ __('Are you sure you want to delete this checklist?') }}
            </div>
        </template>
        <template v-slot:footer>
            <div class="flex justify-end w-full">
                <c-button class="bg-slate-100 mr-2 text-slate-800" v-on:click="deletedChecklist = null">
                    {{ __('Cancel') }}
                </c-button>
                <c-button class="bg-error text-white"
                          v-bind:disabled="!can('checklists.destroy')"
                          v-on:click="delChecklist"
                >
                    {{ __('Delete') }}
                </c-button>
            </div>
        </template>
    </c-modal>
</template>

<script setup lang="ts">
    import { computed, inject, isRef, onMounted, ref, watch } from 'vue';
    import { useRoute, useRouter } from 'vue-router';
    import { vOnClickOutside } from '@vueuse/components';
    import { DateTime } from 'luxon';
    import {
        CButton,
        CControlAutocomplete,
        CControlDate,
        CControlFile,
        CControlNumber,
        CControlSelect,
        CControlTag,
        CControlText,
        CControlToggle,
        CIcon,
        CModal,
    } from '@teamfurther/cinderblock';

    import CustomField from '../../components/CustomField.vue';
    import ChecklistRepository from '../../repositories/ChecklistRepository';
    import EmployeeRepository from '../../repositories/EmployeeRepository';
    import TicketRepository from '../../repositories/TicketRepository';
    import { useLocalizationStore } from '../../stores/localization';
    import { useRequestStore } from '../../stores/request';
    import { useSessionStore } from '../../stores/session';
    import SerialNumberRepository from '../../repositories/SerialNumberRepository';

    const __ = inject('__');
    const addedTask = ref<string>('');
    const addingChecklist = ref<boolean>(false);
    const checklistRepository = ChecklistRepository.getInstance();
    const checklists = ref<Object[]>([]);

    const constraint_intervals = computed(() => {
        let intervals = [
            {
                disabled: true,
                value: 'disabled',
                label: '----------',
            },
            {
                value: 'first',
                label: __('first'),
            },
            {
                value: 'second',
                label: __('second'),
            },
            {
                value: 'third',
                label: __('third'),
            },
            {
                value: 'fourth',
                label: __('fourth'),
            },
            {
                value: 'fifth',
                label: __('fifth'),
            },
            {
                disabled: true,
                value: 'disabled',
                label: '----------',
            },
            {
                value: 'last',
                label: __('last'),
            },
        ];

        if (ticket.value.next_occurrence) {
            intervals.unshift({
                value: 'date',
                label: __('on day :day', { day: DateTime.fromISO(ticket.value.next_occurrence).day }),
            });
        }

        return intervals;
    });

    const constraint_types = [
        {
            value: 'day',
            label: __('day'),
        },
        {
            value: 'weekday',
            label: __('workday'),
        },
        {
            disabled: true,
            value: 'disabled',
            label: '---------------',
        },
        {
            value: 'mon',
            label: __('Monday'),
        },
        {
            value: 'tue',
            label: __('Tuesday'),
        },
        {
            value: 'wed',
            label: __('Wednesday'),
        },
        {
            value: 'thu',
            label: __('Thursday'),
        },
        {
            value: 'friday',
            label: __('Friday'),
        },
        {
            value: 'sat',
            label: __('Saturday'),
        },
        {
            value: 'sun',
            label: __('Sunday'),
        },
    ];

    const deletedChecklist = ref(null);
    const employeeRepository = EmployeeRepository.getInstance();
    const fields = ref<Object[]>([]);

    const frequencies = [
        {
            value: 'days',
            label: __('day(s)'),
        },
        {
            value: 'weeks',
            label: __('week(s)'),
        },
        {
            value: 'months',
            label: __('month(s)'),
        },
        {
            value: 'years',
            label: __('year(s)'),
        },
    ];

    const localizationStore = useLocalizationStore();
    const newChecklistName = ref<string>('');
    const newFilenames = ref<String[]>([]);
    const newFiles = ref<Object[]>([]);
    const overlapTickets = ref<object>();
    const requestStore = useRequestStore();
    const route = useRoute();
    const router = useRouter();
    const selectedTask = ref<string>();
    const serialNumberRepository = SerialNumberRepository.getInstance();
    const sessionStore = useSessionStore();
    const snsAtAnotherSite = ref<String[]>([]);
    const showChecklistMenu = ref<boolean>(false);
    const ticket = ref<object>();
    const ticketId = route.params.ticket;
    const ticketRepository = TicketRepository.getInstance();

    async function addChecklist() {
        addingChecklist.value = false;

        await checklistRepository.store({
            items: ticket.value.tasks,
            name: newChecklistName.value,
        }, {
            include: ['items'],
        }).then((response) => {
            checklists.value.push(response);
            newChecklistName.value = __('Checklist #:number', { number: checklists.value.length + 1 });
        }).catch(() => {
            addingChecklist.value = true;
        });
    }

    function addFieldRepetition(field) {
        const currentRepetitions = computeFieldRepetitions(field);

        field.fields.forEach(subfield => {
            ticket.value.fields.push({
                key: field.key + '.' + currentRepetitions + '.' + subfield.key,
                value: null,
                ticket_id: ticket.value.id,
            });
        });

        computeTicketFieldValues();
    }

    function addTask() {
        ticket.value.tasks.push({
            name: addedTask.value,
            ticket_id: ticket.value.id
        });

        addedTask.value = '';
    }

    function applyChecklist(checklist) {
        ticket.value.tasks = [];

        checklist.items.forEach((item) => {
            ticket.value.tasks.push({
                name: item.name,
                ticket_id: ticket.value.id
            });
        });
    }

    async function checkScheduleOverlaps() {
        if (
            (
                ticket.value.schedule_start_date
                && ticket.value.schedule_start_time
                && !ticket.value.schedule_end_date
                && !ticket.value.schedule_end_time
            )
            || (
                ticket.value.schedule_start_date
                && ticket.value.schedule_start_time
                && ticket.value.schedule_end_date
                && ticket.value.schedule_end_time
            )
        ) {
            overlapTickets.value = await ticketRepository.checkScheduleOverlaps(ticket.value);
        } else {
            overlapTickets.value = [];
        }
    }

    function checkSnsAtAnotherSite() {
        snsAtAnotherSite.value = ticket.value.serial_numbers.filter(
            (sn) => sn.partner_site_id !== ticket.value.partner_site_id
        );
    }

    function closeChecklistMenu() {
        showChecklistMenu.value = false;
    }

    function computeFieldRepetitions(field) {
        let repetitions = null;

        ticket.value.fields.forEach(f => {
            const keyParts = f.key.split('.');

            if (keyParts[0] === field.key && (!repetitions || parseInt(keyParts[1]) + 1 > repetitions)) {
                repetitions = parseInt(keyParts[1]) + 1;
            }
        });

        return repetitions;
    }

    function computeTicketFieldValues() {
        fields.value.forEach(field => {
            if (field.fields) {
                field.fields.forEach(subfield => {
                    const found = ticket.value.fields.findIndex(f => {
                        return f.key === field.key + '.0.' + subfield.key;
                    });

                    if (found === -1) {
                        ticket.value.fields.push({
                            key: field.key + '.0.' + subfield.key,
                            value: null,
                            ticket_id: ticket.value.id,
                        });
                    }
                });
            } else {
                const found = ticket.value.fields.findIndex(f => {
                    return f.key === field.key;
                });

                if (found === -1) {
                    ticket.value.fields.push({
                        key: field.key,
                        value: null,
                        ticket_id: ticket.value.id,
                    });
                }
            }
        });

        ticket.value.fieldValues = ticket.value.fields.reduce((accumulator, field) => {
            accumulator[field.key] = field.value;

            return accumulator;
        }, {});
    }

    async function delChecklist() {
        await checklistRepository.destroy(deletedChecklist.value.id);

        deletedChecklist.value = null;

        await getChecklists();
    }

    function deleteTask(key) {
        ticket.value.tasks.splice(key, 1);
    }

    function deleteFile(key: number, isNew: boolean = false) {
        if (!isNew) {
            ticket.value.files.splice(key, 1);
        } else {
            newFilenames.value.splice(key, 1);
            newFiles.value.splice(key, 1);
        }
    }

    async function employeeGet(employee) {
        if (isRef(employee) && employee.value) {
            return await employeeRepository.show(employee.value.id);
        } else if (employee && employee.id) {
            return await employeeRepository.show(employee.id);
        } else {
            return null;
        }
    }

    async function employeeSearch(val) {
        return await employeeRepository.index({
            filters : {
                search: val
            }
        });
    }

    async function getChecklists() {
        checklists.value = await checklistRepository.index({
            include: ['items'],
        });

        newChecklistName.value = __('Checklist #:number', { number: checklists.value.length + 1 });
    }

    function parseRecurrenceConstraints() {
        ticket.value.recurrence_constraints = {
            months: {
                interval: ticket.value.recurrence_constraints && ticket.value.recurrence_constraints.indexOf(':') !== -1
                    ? ticket.value.recurrence_constraints.split(':')[0]
                    : ticket.value.next_occurrence ? 'date' : 'first',
                type: ticket.value.recurrence_constraints && ticket.value.recurrence_constraints.indexOf(':') !== -1
                    ? ticket.value.recurrence_constraints.split(':')[1]
                    : 'day',
            },
            weeks: {
                monday: ticket.value.recurrence_constraints && ticket.value.recurrence_constraints.indexOf(',') !== -1
                    ? ticket.value.recurrence_constraints.split(',').includes('monday')
                    : true,
                tuesday: ticket.value.recurrence_constraints && ticket.value.recurrence_constraints.indexOf(',') !== -1
                    ? ticket.value.recurrence_constraints.split(',').includes('tuesday')
                    : true,
                wednesday: ticket.value.recurrence_constraints && ticket.value.recurrence_constraints.indexOf(',') !== -1
                    ? ticket.value.recurrence_constraints.split(',').includes('wednesday')
                    : true,
                thursday: ticket.value.recurrence_constraints && ticket.value.recurrence_constraints.indexOf(',') !== -1
                    ? ticket.value.recurrence_constraints.split(',').includes('thursday')
                    : true,
                friday: ticket.value.recurrence_constraints && ticket.value.recurrence_constraints.indexOf(',') !== -1
                    ? ticket.value.recurrence_constraints.split(',').includes('friday')
                    : true,
                saturday: ticket.value.recurrence_constraints && ticket.value.recurrence_constraints.indexOf(',') !== -1
                    ? ticket.value.recurrence_constraints.split(',').includes('saturday')
                    : false,
                sunday: ticket.value.recurrence_constraints && ticket.value.recurrence_constraints.indexOf(',') !== -1
                    ? ticket.value.recurrence_constraints.split(',').includes('sunday')
                    : false
            }
        };
    }

    function parseScheduleTimes() {
        if (ticket.value.schedule_start_time) {
            ticket.value.schedule_start_time = {
                hours: ticket.value.schedule_start_time.split(':')[0],
                minutes: ticket.value.schedule_start_time.split(':')[1],
            };
        }

        if (ticket.value.schedule_end_time) {
            ticket.value.schedule_end_time = {
                hours: ticket.value.schedule_end_time.split(':')[0],
                minutes: ticket.value.schedule_end_time.split(':')[1],
            };
        }
    }

    async function snGet(sn) {
        if (isRef(sn) && sn.value) {
            return ticket.value.serial_numbers.find((serialNumber) => serialNumber.id === sn.value.id)
        } else if (sn && sn.id) {
            return ticket.value.serial_numbers.find((serialNumber) => serialNumber.id === sn.id)
        } else {
            return null;
        }
    }

    async function snSearch(val) {
        return await serialNumberRepository.index({
            filters : {
                search: val
            },
            include: ['partNumber'],
        });
    }

    async function submit() {
        ticket.value.newFilenames = newFilenames.value;
        ticket.value.newFiles = newFiles.value;

        ticket.value = await ticketRepository.update(ticketId, ticket.value, {
            include: [
                'additionalAssignees',
                'assignee',
                'fields',
                'files',
                'serialNumbers',
                'site',
                'site.partner',
                'tasks'
            ]
        });

        computeTicketFieldValues();
        parseRecurrenceConstraints();
        parseScheduleTimes();

        if (ticket.value.recurrence_interval) {
            ticket.value.recurrence = true;
        }

        newFilenames.value = [];
        newFiles.value = [];
        snsAtAnotherSite.value = [];
    }

    function upload() {
        document.getElementById('ticket.files').click();
    }

    onMounted(async () => {
        await requestStore.get('tickets/fields').then(async (response : any) => {
            fields.value = response.data;
        });

        ticket.value = await ticketRepository.show(ticketId, {
            include: [
                'additionalAssignees',
                'assignee',
                'fields',
                'files',
                'serialNumbers',
                'site',
                'site.partner',
                'tasks'
            ]
        });

        computeTicketFieldValues();
        parseRecurrenceConstraints();
        parseScheduleTimes();

        ticket.value.recurrence = !!ticket.value.recurrence_interval;
        ticket.value.recurrence_interval = ticket.value.recurrence_interval ?? 1;
        ticket.value.recurrence_frequency = ticket.value.recurrence_frequency ?? 'days';

        await checkScheduleOverlaps();

        await getChecklists();
    });

    watch(() => ticket.value, () => {
        Object.keys(ticket.value.fieldValues).forEach(key => {
            let field = ticket.value.fields.find(f => f.key === key);

            if (field) {
                field.value = ticket.value.fieldValues[key];
            }
        });
    }, { deep: true });

    watch(() => ticket.value?.assignee, () => {
        ticket.value.assignee_id = ticket.value.assignee?.id ?? null;
    });

    watch(() => route.params.ticket, () => {
        window.location.reload();
    });
</script>